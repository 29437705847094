import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { LazyLoadEvent } from 'primeng/api';
import { TableDropdownOptions, TableFilter } from '../../models/table-filter';
import { TableColumn, TableEvent } from '../../models/tabe-column';
import { TableParams } from '../../models/table-params';
import { SearchBy } from '../../models/search-by';

@Component({
    selector: 'registry-table-page',
    templateUrl: './table-page.component.html',
    styleUrls: ['./table-page.component.scss'],
})
export class TablePageComponent<T> {
    @Input() pageHeader = true;
    @Input() entity!: string;
    @Input() title!: string;
    @Input() url!: string;
    @Input() insertLabel!: string;
    @Input() filterForm!: FormGroup;
    @Input() keys!: TableFilter[];
    @Input() options!: TableDropdownOptions[];
    @Input() quickSearch = false;
    @Input() page!: string;
    @Input() categoryOptions: any; 
    @Input() searchBy!: SearchBy[];
    @Input() dataRows!: T[];
    @Input() totalItems!: number;
    @Input() first!: number;
    @Input() pageSize!: number;
    @Input() header!: string[];
    @Input() columns!: TableColumn[];
    @Input() displayedColumns!: TableColumn[];
    @Input() isMobile!: boolean;
    @Input() emptyTableMessage!: string;

    @Output() selectedSearch = new EventEmitter();
    @Output() filterSubmited = new EventEmitter();

    @Output() inputCleared = new EventEmitter();
    @Output() option = new EventEmitter();

    @Output() tableLoaded = new EventEmitter();
    @Output() searchDialogClicked = new EventEmitter();
    @Output() disableEntityClicked: EventEmitter<number> = new EventEmitter();
    @Output() enableEntityClicked: EventEmitter<number> = new EventEmitter();
    @Output() editEntityClicked: EventEmitter<number> = new EventEmitter();
    @Output() optionToggled = new EventEmitter();
    @Output() pageHandle = new EventEmitter();

    selectOptions(event: TableFilter | undefined): void {
        this.selectedSearch.emit(event);
    }

    onOption(option: string): void {
        this.option.emit(option);
    }

    searchByFilter(event: TableParams): void {
        this.filterSubmited.emit(event);
    }

    resetField(event: string): void {
        this.inputCleared.emit(event);
    }

    onLazyLoad(event: LazyLoadEvent): void {
        this.tableLoaded.emit(event);
    }

    showSearchDialog(): void {
        this.searchDialogClicked.emit();
    }

    toggleOption(event: TableEvent<T>): void {
        this.optionToggled.emit(event);
    }

    handlePagination(event: any): void {
        this.pageHandle.emit(event);
    }
}
