import { AbstractControl, FormGroup, ValidationErrors } from "@angular/forms";
import { ToastService } from "../services/toast/toast.service";

export function checkEditError(error: ErrorEvent, toastService: ToastService, entityName: string): void {
    error.error.descrizione ? toastService.addToast({ severity: 'error', summary: 'Rifiutato', detail: error.error.descrizione })
        :
        toastService.addToast({ severity: 'error', summary: 'Rifiutato', detail: `${entityName} non possono essere modificati..` });
}

export function checkError(id: string | number | undefined, toastService: ToastService, entityName: string, gender: string): void {
    let detail: string;

    if (id) {
        if (gender === 'F')
            detail = 'Impossibile modificare la';
        else
            detail = 'Impossibile modificare il';
    } else {
        if (gender === 'F')
            detail = 'Impossibile creare la';
        else
            detail = 'Impossibile creare il';
    }

    toastService.addToast({ severity: 'error', summary: 'Rifiutato', detail: `${detail} ${entityName}` });
}

export function hasErrors(componentName: string, errorCode?: string, form?: FormGroup | AbstractControl, submitted?: boolean): boolean | ValidationErrors | '' | undefined {
    return ((form?.get(componentName)?.dirty ||
        form?.get(componentName)?.touched || submitted) &&
        ((!errorCode || form?.get(componentName)?.errors) ||
            (errorCode && form?.get(componentName)?.hasError(errorCode)))
    );
}


