import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { MenubarModule } from 'primeng/menubar';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { PaginatorModule } from 'primeng/paginator';
import { DividerModule } from 'primeng/divider';
import { MessageModule } from 'primeng/message';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { ConfirmationService, MessageService } from 'primeng/api';
import { SkeletonModule } from 'primeng/skeleton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { FileUploadModule } from 'primeng/fileupload';
import { CalendarModule } from 'primeng/calendar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TagModule } from 'primeng/tag';
import { AccordionModule } from 'primeng/accordion';
import { ChipModule } from 'primeng/chip';
import { PanelMenuModule } from 'primeng/panelmenu';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { SelectButtonModule } from 'primeng/selectbutton';

const modules = [
    ButtonModule,
    MenubarModule,
    InputTextModule,
    MenuModule,
    SidebarModule,
    TableModule,
    DropdownModule,
    DialogModule,
    PaginatorModule,
    DividerModule,
    MessageModule,
    ConfirmDialogModule,
    ToastModule,
    SkeletonModule,
    ToolbarModule,
    TooltipModule,
    FileUploadModule,
    CalendarModule,
    ProgressSpinnerModule,
    TagModule,
    AccordionModule,
    ChipModule,
    PanelMenuModule,
    AutoCompleteModule,
    InputSwitchModule,
    InputTextareaModule,
    TriStateCheckboxModule,
    SelectButtonModule
];

@NgModule({
    imports: [modules],
    declarations: [],
    exports: [modules],
    providers: [ConfirmationService, MessageService],
})
export class PrimeNgModule { }
