/**
 * Here you can add the configuration related to keycloak
 * So we can use this common config for diffrent environment
 */
import { KeycloakConfig } from 'keycloak-js';

// Develop
/* const keycloakConfig: KeycloakConfig = {
  url: 'https://keycloak.pandoradevelop.it',
  realm: 'pandora',
  clientId: 'app',
}; */

// Localhost
const keycloakConfig: KeycloakConfig = {
   url: 'https://keycloak.pandoradevelop.it',
   realm: 'pandora',
   clientId: 'app-local',
 };

// Docker
/* const keycloakConfig: KeycloakConfig = {
  url: 'http://keycloak.pandoradevelop.it',
  realm: 'pandora',
  clientId: 'angular-web-client',
}; */

export default keycloakConfig;
