import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'registry-edit-disable-enable-buttons',
    templateUrl: './edit-disable-enable-buttons.component.html',
})
export class EditDisableEnableButtonsComponent {
    @Output() enableData = new EventEmitter();
    @Output() disableData = new EventEmitter();
    @Input() entity!: any;
    @Input() url!: string;

    readonly edit = {
        label: 'COMMON.EDIT',
        command: () => this.router.navigate([this.url], { relativeTo: this.route }),
        icon: 'pi pi-file-edit',
    };

    readonly enable = {
        label: 'COMMON.ENABLE',
        command: () => this.enableEntity(),
        icon: 'pi pi-plus',
    };

    readonly disable = {
        label: 'COMMON.DISABLE',
        command: () => this.disableEntity(),
        icon: 'pi pi-times',
    };

    constructor(private router: Router, private route: ActivatedRoute) { }

    enableEntity(): void {
        this.enableData.emit('enable');
    }

    disableEntity(): void {
        this.disableData.emit('disable');
    }
}
