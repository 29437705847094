/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Injector, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthFacade, FlowersBasePageComponent } from '@pandora/flowers';

@Component({
    selector: 'flowers-home',
    templateUrl: './home.component.html',
})
export class HomeComponent extends FlowersBasePageComponent {
    // user: User;
    authFacade: AuthFacade;

    constructor(injector: Injector, private readonly router: Router) {
        super(injector);
        this.authFacade = inject(AuthFacade);
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function

    ngOnDestroyForChildren(): void { /* TODO document why this method 'ngOnDestroyForChildren' is empty */ }

    ngOnInitForChildren(): void {
        // this.authFacade.getUser().pipe(takeUntil(this.onDestroy$)).subscribe((user: User) => {
        //   this.user = user;

        //   if (this.user) {
        //     if (!('profileSelected' in this.user) || !this.user.profileSelected) {
        //       this.router.navigate(['profile']);
        //     } else {
        //       this.uiElement.initMenuItems();
        //     }
        //   }
        // });
    }

    pageName(): string {
        return 'HomeComponent';
    }
}
