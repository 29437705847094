import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { TableColumn } from '../../models/tabe-column';
import { AbstractControl, FormArray, FormGroup, ValidationErrors } from '@angular/forms';
import { hasErrors } from '../../utils/check-error.component';

@Component({
    selector: 'registry-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
})
export class TableComponent<T> {
    @Input() dataRows!: T[];
    @Input() totalItems!: number;
    @Input() first!: number;
    @Input() searchForm!: FormGroup;
    @Input() filteredData!: any;
    @Input() pageSize!: number;
    @Input() columns!: TableColumn[];
    @Input() isLoading!: boolean;
    @Input() displayedColumns!: TableColumn[];
    @Input() scrollable!: boolean;
    @Input() scrollHeight!: string;
    @Input() tableStyle!: string;
    @Input() isMobile!: boolean;
    @Input() form!: FormGroup;
    @Input() submitted!: boolean;
    @Input() emptyTableMessage!: string;

    @Output() tableLoaded = new EventEmitter();
    @Output() searchDialogClicked = new EventEmitter();
    @Output() disableEntityClicked: EventEmitter<number> = new EventEmitter();
    @Output() enableEntityClicked: EventEmitter<number> = new EventEmitter();
    @Output() editEntityClicked: EventEmitter<number> = new EventEmitter();
    @Output() optionToggled = new EventEmitter();
    @Output() itemSelected = new EventEmitter();
    @Output() pageHandle = new EventEmitter();

    onLazyLoad(event: LazyLoadEvent): void {
        this.tableLoaded.emit(event);
    }

    showSearchDialog(): void {
        this.searchDialogClicked.emit();
    }

    editEntity(entityId: number) {
        this.editEntityClicked.emit(entityId);
    }

    disableEntity(entityId: number): void {
        this.disableEntityClicked.emit(entityId);
    }

    enableEntity(entityId: number): void {
        this.enableEntityClicked.emit(entityId);
    }

    toggleOption(id: string, data: T, ri: number): void {
        this.optionToggled.emit({ id: id, ri: ri, data: data });
    }

    selectItem(event: any): void {
        this.itemSelected.emit(event);
    }

    handlePagination(event: any): void {
        this.pageHandle.emit(event);
    }

    getFormArray(formArray: string | undefined): FormArray | undefined {
        let arr;
        if (formArray) {
            arr = this.form.controls[formArray] as FormArray;
        } else {
            arr = undefined;
        }

        return arr;
    }

    isNumber(val: number | undefined): boolean { return typeof val === 'number'; }

    toNumber(val: number | undefined): number {
        return Number(val);
    }

    toBoolean(val: boolean | undefined): boolean {
        return Boolean(val);
    }

    // Checks form errors
    hasArrErrors(componentName: string, ctrl: AbstractControl, errorCode?: string): boolean | ValidationErrors | '' | undefined {
        return hasErrors(componentName, errorCode, ctrl, this.submitted);
    }

    setInputWidth(width: string): string {
        if (width) {
            return `width: ${width}`;
        } else {
            return 'width: auto';
        }
    }
}
