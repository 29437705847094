import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CustomBaseHttpLiteService, CustomSearchCriteria } from '@pandora/flowers';
import { ConfirmationService } from 'primeng/api';
import { FormDialogService } from './form-dialog/form-dialog.service';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { ToastService } from './toast/toast.service';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class EnableDisableService extends CustomBaseHttpLiteService<any> {
    module!: string;
    confirmationService!: ConfirmationService;
    formDialogService!: FormDialogService;
    toastService!: ToastService;
    location!: Location;

    constructor(injector: Injector, private translateService: TranslateService) {
        super(injector);
        this.confirmationService = this.injector.get(ConfirmationService);
        this.formDialogService = this.injector.get(FormDialogService);
        this.toastService = this.injector.get(ToastService);
        this.location = this.injector.get(Location);
    }

    override getHost(): string {
        if (this.module !== 'regions' && this.module !== 'provinces' && this.module !== 'municipalities') {
            const base_url: string = environment.base_protocol + "://" +
                environment.base_root_domain +
                (environment.base_port ? ":" + environment.base_port + "/" : "");
            return base_url;
        }
        else {
            const base_url: string = environment.base_protocol + "://" +
                environment.geo_base_root_domain +
                (environment.geo_base_port ? ":" + environment.geo_base_port + "/" : "");
            return base_url;
        }
    }

    buildSearchParams(criteria: CustomSearchCriteria): any {
        const params = {};
        return params;
    }

    getBaseResourceEndpoint(): string {
        return this.module;
    }

    getServiceName(): string {
        return 'EnableDisableService';
    }

    getTranslate(title: string): Observable<any> {
        return this.translateService.get(title);
    }


    // Calls confirmation popup
    hanldeEnableDisable(m: string, title: string, model: any, path: string, redirect?: boolean): void {
        this.module = m;

        this.confirmationService.confirm({
            message: this.translateService.instant(title + '.' + path.toUpperCase() + '.HEADER_MESSAGE'),
            header: this.translateService.instant(title + '.' + path.toUpperCase() + '.HEADER'),
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                // Calls the service
                this.enableDisable(m, model.id, path, title, redirect);
            }
        });
    }

    // Enables or disables entity when accepted
    enableDisable(m: string, id: number | string | undefined, path: string, title: string, redirect?: boolean): void {
        this.updateWithPath(m, id, path).subscribe({
            next: () => {
                // Sets success message
                this.toastService.addToast({
                    severity: 'info',
                    summary: 'Confermato',
                    detail: this.translateService.instant(title + '.' + path.toUpperCase() + '.CONFIRM'),
                });
                // Sends data to formDialogService
                this.formDialogService.submitSuccessful.next(true);
                if (redirect) {
                    this.redirect();
                }
            },
            error: () => {
                // Sets error message
                this.toastService.addToast({
                    severity: 'error',
                    summary: 'Rifiutato',
                    detail: this.translateService.instant(title + '.' + path.toUpperCase() + '.ERROR'),
                });
            },
        });
    }

    // Navigates to parent route
    redirect(): void {
        this.location.back();
    }
}
