<div class="flex w-full">
    <flowers-button [item]="edit" [id]="'edit'" [extraClass]="'text-xs mr-2'"></flowers-button>

    <ng-container *ngIf="entity.isValid; else enableBtn">
        <flowers-button [item]="disable" [id]="'disable'"
            [extraClass]="'text-xs p-button-danger disable-button'"></flowers-button>
    </ng-container>

    <ng-template #enableBtn>
        <flowers-button [item]="enable" [id]="'enable'"
            [extraClass]="'text-xs p-button-success enable-button'"></flowers-button>
    </ng-template>
</div>