import { format } from "date-fns";

// Formatting date using date-fns
export function formattingDate(fromDate: string, toDate: string): [string, string] {
    if (fromDate) {
        fromDate = format(new Date(formattingDateFromString(fromDate)), "yyyy-MM-dd");
    }
    if (toDate) {
        toDate = format(new Date(formattingDateFromString(toDate)), "yyyy-MM-dd");
    }

    return [fromDate, toDate];
}

export function formattingDateFromString(value: string): Date {
    if (typeof value === 'string') {
        const dateArray = value.split('/');
        return new Date(parseInt(dateArray[2]), parseInt(dateArray[1]) - 1, parseInt((dateArray[0])));
    }

    return value;
}
