import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TableParams } from '../../../shared/models/table-params';
import { TableDropdownOptions, TableFilter } from '../../models/table-filter';

@Component({
    selector: 'registry-table-filters',
    templateUrl: './table-filters.component.html',
    styleUrls: ['./table-filters.component.scss'],
})
export class TableFiltersComponent implements OnInit {
    @Input() filterForm!: FormGroup;
    @Input() keys!: TableFilter[];
    @Input() options!: TableDropdownOptions[];
    @Input() entity!: string;
    @Input() quickSearch!: boolean;
    @Input() page!: string;
    @Input() categoryOptions?: any;
    @Output() selectedSearch = new EventEmitter<TableFilter>();
    @Output() filterSubmited = new EventEmitter<TableParams>();
    @Output() option: EventEmitter<string> = new EventEmitter();
    searchByCtrl = new FormControl();
    searchBy!: TableFilter | undefined;

    ngOnInit(): void {
        const defaultValue = this.keys.find(k => k.default === true);
        this.searchByCtrl.setValue(defaultValue?.key);
        this.searchBy = defaultValue;

        this.searchByCtrl.valueChanges.subscribe((res) => {
            if (res) {
                this.searchBy = res;
            } else {
                this.searchBy = this.keys.find(k => k.default === true);
            }
            this.selectedSearch.emit(this.searchBy);
        });
    }

    searchByFilter(): void {
        this.filterSubmited.emit(this.filterForm.value);
    }

    onOption(option: string): void {
        this.option.emit(option);
    }
}
