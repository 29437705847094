<div *ngIf="searchBy.length > 0" class="searched-by px-3 py-2 mb-2">
    <!--Show title on small screens-->
    <ng-container *ngIf="isMobile">
        <h4 class="title">Search by:</h4>
    </ng-container>

    <div class="flex justify-content-between">
        <!--Search by chips-->
        <div class="flex flex-wrap gap-1">
            <p-chip *ngFor="let searched of searchBy" label="{{searched.label}}: {{searched.value}}" [removable]="true"
                (onRemove)="clearInput(searched.key)" [styleClass]="'capitalize searched-by'"></p-chip>
        </div>

        <button type="button" pButton type="submit" id="clearFilterSm" (click)="clearFilters()" icon="pi pi pi-times"
            class="p-button-sm p-button-rounded btn-rounded-sm primary-btn show-details-button" pTooltip="Ripristinare"
            tooltipPosition="top"></button>
    </div>
</div>