import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, ValidationErrors } from '@angular/forms';
import { Country } from '../../models/country';
import { Region } from '../../../teritorial-units/regions/models/region';
import { Province } from '../../../teritorial-units/provinces/models/province';
import { Municipality } from '../../../teritorial-units/municipalities/models/municipality';
import { CountryService } from '../../countries/services/country.service';
import { RegionsService } from '../../../teritorial-units/regions/services/regions.service';
import { ProvincesService } from '../../../teritorial-units/provinces/services/provinces.service';
import { Subscription } from 'rxjs';
import { ToastService } from '../../services/toast/toast.service';
import { ErrorLabelConstants, ErrorLabelsMaps } from '@pandora/flowers';
import { checkDateInputs } from '../../utils/check-date-inputs.component';
import { formattingDateFromString } from '../../utils/formatting-date';

@Component({
    selector: 'registry-address',
    templateUrl: './address.component.html',
})
export class AddressComponent implements OnInit, OnDestroy {
    @Input() formData!: FormGroup;
    @Input() addressData!: FormGroup;
    @Input() minToDateAddress!: Date;
    @Input() minToDate!: Date;
    @Input() entity!: any;
    @Output() newMinFromDateEvent = new EventEmitter<Date>();
    countries!: Country[];
    regions!: Region[];
    provinces!: Province[];
    municipalities!: Municipality[];
    subscription: Subscription = new Subscription();
    errorLabels: ErrorLabelsMaps = {};

    constructor(
        private httpCountries: CountryService,
        private httpRegionsService: RegionsService,
        private httpProvincesService: ProvincesService,
        private toastService: ToastService,
    ) { }

    ngOnInit(): void {
        this.errorLabels = this.createAndUseAddressErrorLabels(this.errorLabels) || this.errorLabels;
        this.onFormEvent();
        this.getCountries();
    }

    ngOnDestroy(): void {
        if (this.subscription) this.subscription.unsubscribe();
    }

    // Calls the countries list from the service
    getCountries(): void {
        this.subscription.add(
            this.httpCountries.getCountries().subscribe((country) => (this.countries = country))
        );
    }

    /**
     * @param country
     * Get regions of current country
     */
    getCountryRegions(country: string): void {
        this.subscription.add(this.httpRegionsService.getAll({ country, isValid: true }, undefined, true).subscribe({
            next: (regions) => {
                this.regions = regions.content;
            },
            error: () => {
                /**
                 * Sets error message
                 */
                this.toastService.addToast({ severity: 'error', summary: 'Rifiutato', detail: 'Regione non è caricato!' });
            }
        }));
    }

    /**
     * @param id
     * Get provinces by region id
     */
    getRegionProvinces(id: number): void {
        this.subscription.add(this.httpRegionsService.getAll({ id, isValid: true }, undefined, true).subscribe({
            next: (regions) => {
                this.provinces = regions.content[0].provinces;
            },
            error: () => {
                /**
                 * * Sets error message
                 * */
                this.toastService.addToast({ severity: 'error', summary: 'Rifiutato', detail: 'Provincia non è caricato!' });
            }
        }));
    }

    /**
     * @param id
     * Get municipalities by province id
     */
    getProvinceMunicipalities(id: number): void {
        this.subscription.add(this.httpProvincesService.getAll({ id, isValid: true }, undefined, true).subscribe({
            next: (regions) => {
                this.municipalities = regions.content[0].municipalities;
            },
            error: () => {
                /**
                 * * Sets error message
                 * */
                this.toastService.addToast({ severity: 'error', summary: 'Rifiutato', detail: 'Città non è caricato!' });
            }
        }));
    }

    // Listens for form events
    onFormEvent(): void {
        const addressFromDate = this.addressData.get('fromDate')!;
        const addressToDate = this.addressData.get('toDate')!;
        const country = this.addressData?.get('country');
        const region = this.addressData?.get('regionId');
        const province = this.addressData?.get('provinceId');
        const city = this.addressData?.get('cityId');

        if (this.entity) {
            this.addressData.patchValue({
                street: this.entity.address.street,
                streetNumber: this.entity.address.streetNumber,
                postalCode: this.entity.address.postalCode,
                country: this.entity.address.country,
                regionId: this.entity.address.regionId,
                provinceId: this.entity.address.provinceId,
                cityId: this.entity.address.cityId,
                fromDate: new Date(this.entity.address.fromDate),
                toDate: this.entity.address.toDate ? new Date(this.entity.address.toDate) : null,
            });

            this.getRegionProvinces(this.entity.address.regionId);
            this.getProvinceMunicipalities(this.entity.address.provinceId);
        }

        this.getCountryRegions(country?.value);

        country?.valueChanges.subscribe(res => {
            if (!res) {
                region?.setValue(null);
                this.regions = [];
                return;
            }

            this.getCountryRegions(res);
        });

        region?.valueChanges.subscribe(res => {
            if (!res) {
                province?.setValue(null);
                this.provinces = [];
                return;
            }

            this.getRegionProvinces(res);
        });

        province?.valueChanges.subscribe(res => {
            if (!res) {
                city?.setValue(null);
                this.municipalities = [];
                return;
            }

            this.getProvinceMunicipalities(res);
        });

        // Configures toDate input if fromDate is filled
        checkDateInputs(addressFromDate.value, addressToDate);
    }

    setMinToDateAddress(newMinFromDate: Date | string): void {
        if (typeof newMinFromDate === 'string')
            this.minToDateAddress = formattingDateFromString(newMinFromDate);
        else
            this.minToDateAddress = newMinFromDate;

        this.newMinFromDateEvent.emit(newMinFromDate as Date);
    }

    createAndUseAddressErrorLabels(errorLabel: ErrorLabelsMaps): ErrorLabelsMaps {
        return {
            maxLengthStreet: [
                ErrorLabelConstants.REQUIRED,
                ErrorLabelConstants.MAX_LENGTH(40),
            ],
            maxLengthStreetNumber: [
                ErrorLabelConstants.REQUIRED,
                ErrorLabelConstants.MAX_LENGTH(40),
            ],
            maxLengthPostalCode: [
                ErrorLabelConstants.REQUIRED,
                ErrorLabelConstants.MAX_LENGTH(60),
            ],
            required: [ErrorLabelConstants.REQUIRED],
        };
    }

    // Checks form errors
    hasErrors(componentName: string, errorCode?: string): boolean | ValidationErrors | '' | undefined {
        return (
            (this.formData?.get(componentName)?.dirty ||
                this.formData?.get(componentName)?.touched ||
                this.formData) &&
            ((!errorCode && this.formData.get(componentName)?.errors) ||
                (errorCode &&
                    this.formData.get(componentName)?.hasError(errorCode)))
        );
    }
}
