<div class="pt-2">
    <!--Form header template-->
    <ng-content select="[header]"></ng-content>

    <div class="form p-4 mb-3">
        <!--Form content template-->
        <div class="my-3">
            <ng-content select="[content]"></ng-content>
        </div>

        <!--Form footer template-->
        <div class="my-3">
            <ng-content select="[footer]"></ng-content>
        </div>
    </div>
</div>