import { Component, Input, OnInit, OnDestroy, HostListener, ChangeDetectorRef, AfterViewChecked, AfterViewInit, Injector } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { BehaviorSubject, filter, Subscription } from 'rxjs';
import { DirectionSort } from '../../models/table-sort.enum';
import { WindowSizeService } from '../../services/window-size.service';
import { PageDto, PaginationData } from '../../models/page-dto';
import { TableParams } from '../../models/table-params';
import { TableColumn } from '../../models/tabe-column';
import { FormDialogService } from '../../services/form-dialog/form-dialog.service';
import { ActivatedRoute } from '@angular/router';
import { SearchBy } from '../../models/search-by';
import { FormGroup } from '@angular/forms';
import { TableDropdownOptions } from '../../models/table-filter';

@Component({
    template: ''
})
export abstract class DataTableComponent<T> implements OnInit, AfterViewChecked, OnDestroy, AfterViewInit {
    displayedColumns!: TableColumn[];
    displayedMobileColumns!: TableColumn[];
    headerData!: string[];
    headerMobileData!: string[];
    dataRows!: T[];
    private items$ = new BehaviorSubject<PageDto<T> | T[]>(this.items);
    @Input()
    set items(pagedItems: PageDto<T> | T[]) {
        this.items$.next(pagedItems);
    }
    paged!: boolean;
    totalItems = 0;
    isLoading = true;
    pageSize = 5;
    currentPage = 0;
    currentValidity = 'true';
    sortBy!: string;
    direction: string = DirectionSort.ASC;
    paramsObject!: TableParams;
    statusOptions = [{ name: 'Attivo', value: 'true' }, { name: 'Inattivo', value: 'false' }, { name: 'Tutto', value: null }];
    protected subscription: Subscription = new Subscription();
    header!: string[];
    columns!: TableColumn[];
    isMobile!: boolean;
    displaySearchModal!: boolean;
    first!: number;
    searchBy: SearchBy[] = [];
    hasAdditionalSearch!: boolean;
    formData!: T;
    filterForm!: FormGroup;
    options!: TableDropdownOptions[];
    columnTypes!: any[];
    windowSizeService: WindowSizeService;
    formDialogService: FormDialogService;
    cdr: ChangeDetectorRef;
    route: ActivatedRoute;

    constructor(protected injector: Injector) {
        this.windowSizeService = this.injector.get(WindowSizeService);
        this.formDialogService = this.injector.get(FormDialogService);
        this.cdr = this.injector.get(ChangeDetectorRef);
        this.route = this.injector.get(ActivatedRoute);
    }

    ngOnInit(): void {
        this.setParams();
        this.checkSubmit();
        this.getTable();
    }

    ngOnDestroy(): void {
        if (this.subscription) this.subscription.unsubscribe();
    }

    ngAfterViewChecked(): void {
        this.cdr.detectChanges();
    }

    ngAfterViewInit(): void {
        this.cdr.detectChanges();
    }

    /**
     * Set Params
     */
    setParams(): void {
        if (this.route.queryParams !== undefined) {
            this.subscription.add(
                this.route.queryParams.subscribe(params => {
                    this.paramsObject = { ...params };
                })
            );
        }
    }

    /**
     * Check submit
     */
    checkSubmit(): void {
        this.subscription.add(
            this.formDialogService.submitSuccessful.subscribe((successful) => {
                if (successful) {
                    this.formDialogService.submitSuccessful.next(false);
                    this.loadTable();
                    this.updateTable();
                }
            })
        );
    }

    initTable(): void {
        this.subscription.add(this.items$.pipe(filter(c => !!c)).subscribe({ next: (pagedItems: PageDto<T> | T[]) => this.handleTableData(pagedItems) }));
    }

    /**
     * Handles data from api and adding it to the table
     *
     * @param pagedData PageDto<T>
     */
    handleTableData(pagedData: PageDto<T> | T[] | any): void {
        this.currentValidity = this.paramsObject["isValid"];
        this.isLoading = false;

        if (this.checkIfPaged(pagedData)) {
            this.dataRows = pagedData.content;
            this.totalItems = pagedData.pagination.totalElements;
            this.pageSize = this.paramsObject.size ? this.paramsObject.size : this.pageSize;
            this.currentPage = this.paramsObject.page ? this.paramsObject.page : this.currentPage;
            this.first = this.currentPage * this.pageSize;
            this.paged = true;
        } else {
            this.dataRows = pagedData;
            this.paged = false;
        }

        if (this.columnTypes && this.columnTypes.length) {
            this.setColumnsType(this.dataRows, this.columnTypes);
        } else {
            this.setColumnsType(this.dataRows);
        }
    }

    /**
     *
     * @param data
     * @param columnTypes
     * Sets columns types
     */
    setColumnsType(data: any, columnTypes?: any[]): void {
        this.displayedColumns.forEach(col => {
            col.types = [];
            for (let i = 0; i < data.length; i++) {
                const d = data[i];

                col.types?.push({ type: 'text' });

                this.checkColumnTypes(d, col, i, columnTypes);
            }
        });
    }

    checkColumnTypes(d: any, col: TableColumn, i: number, columnTypes?: any[]): void {
        if (columnTypes && columnTypes?.length > 0) {
            columnTypes?.forEach(ct => {
                ct.columns.forEach((c: any) => {
                    if (col.types && col.colLabel === c.label) {
                        if (ct.condition !== null) {
                            this.checkCondition(d, ct, c, col, i);
                        } else {
                            col.types.splice(i, i + 1, c.type);
                        }
                    }
                });
            });
        }
    }

    checkCondition(d: any, ct: any, c: any, col: TableColumn, i: number): void {
        if (d[ct.condition]) {
            col.types.splice(i, i + 1, c.type);
        } else {
            col.types.splice(i, i + 1, { type: 'text' });
        }
    }

    /**
     *
     * @param pagedData
     * @returns content in paged data
     */
    checkIfPaged(pagedData: any): pagedData is PageDto<T> {
        return 'content' in pagedData;
    }

    /**
     * Handles table pagination
     *
     * @param paginationData
     */
    handlePagination(paginationData: PaginationData): void {
        this.paramsObject.page = paginationData.page;
        this.paramsObject.size = paginationData.rows;
        this.currentPage = this.paramsObject.page;
        this.updateTable();
    }

    /**
     * Triggers when table is loaded
     *
     * @param event LazyLoadEvent
     */
    onLazyLoad(event: LazyLoadEvent): void {
        const sortBy = event.sortField;
        const sortOrder = event.sortOrder === -1 ? DirectionSort.DESC : DirectionSort.ASC;

        if (sortBy) {
            this.paramsObject.sort = `${sortBy},${sortOrder}`;
        }

        this.updateTable();
    }

    /**
     * Calls when the table page loads
     */
    abstract getTable(): void;

    /**
     * Updates table when it's called
     */
    abstract updateTable(): void;

    /**
     * Set components params
     */
    abstract setComponentsParams(): void;

    /**
     * Calls table functions
     */
    loadTable(): void {
        this.initDisplayedColumns();
        this.initTable();
        if (this.paramsObject !== undefined && Object.keys(this.paramsObject).length === 0) {
            this.setComponentsParams();
        }
    }

    /**
     * Sets default search parameters
     */
    setDefaultParams(): void {
        this.paramsObject['page'] = this.currentPage;
        this.paramsObject['size'] = this.pageSize;
        this.paramsObject['sort'] = `${this.sortBy},${this.direction}`;
        this.paramsObject['isValid'] = this.currentValidity;
    }

    /**
     *
     * @returns columns depending on screen size
     */
    getDisplayedColumns(): TableColumn[] {
        if (this.windowSizeService.isLg) {
            return this.displayedColumns?.filter(c => c.isMobile);
        } else {
            return this.displayedColumns?.filter(c => c.isDesktop);
        }
    }

    /**
     *
     * @returns true if device screen is less than 768px
     */
    checkMobile(): boolean {
        if (this.windowSizeService.isLg) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Sets table headers and columns depending on screen size
     */
    @HostListener('window:resize', ['$event'])
    initDisplayedColumns(): void {
        this.columns = this.getDisplayedColumns();
        this.isMobile = this.checkMobile();
    }

    /**
     *
     * @param event Iterating reactive form data
     */
    searchByFilter(event: TableParams): void {
        if (JSON.stringify(event) == JSON.stringify(this.formData)) {
            return;
        }

        this.setNewParams(event);
        this.updateTable();
    }

    /**
     *
     * @param event
     * Set new params
     */
    setNewParams(params: TableParams): void {
        for (const key in params) {
            if (typeof params[key] === 'object') {
                for (const subKey in params[key]) {
                    this.checkIfParamIsObject(params, key, subKey);
                }
                this.setSearchedPagination();
            }

            if (Object.prototype.hasOwnProperty.call(params, key) && (params[key] !== null && params[key] !== '' && typeof params[key] !== 'object' && params[key] !== undefined)) {
                this.paramsObject[key] = params[key];
                this.setSearchedPagination();
            } else {
                delete this.paramsObject[key];
            }
        }
    }

    checkIfParamIsObject(params: TableParams, key: string, subKey: string) {
        if (typeof params[key][subKey] !== 'object') {
            this.paramsObject[`${key}.` + subKey] = params[key][subKey];
            delete params[key][subKey];
        } else {
            for (const subKey1 in params[key][subKey]) {
                if (typeof params[key][subKey][subKey1] !== 'object') {
                    this.paramsObject[`${key}.${subKey}.${subKey1}`] = params[key][subKey][subKey1];
                    delete params[key][subKey][subKey1];
                } else {
                    for (const subKey2 in params[key][subKey][subKey1]) {
                        if (params[key][subKey][subKey1][subKey2]) {
                            this.paramsObject[`${key}.${subKey}.${subKey1}.${subKey2}`] = params[key][subKey][subKey1][subKey2];
                            delete params[key][subKey][subKey1][subKey2];
                        }
                    }
                }
            }
        }
    }

    /**
     * Set page afer search
     */
    setSearchedPagination(): void {
        this.paramsObject['page'] = 0;
        this.paramsObject['size'] = 5;
    }

    /**
     * Set search by list
     */
    setSearchBy(data: SearchBy[]): void {
        this.searchBy = [];
        for (const d of data) {
            if (d.value) {
                this.searchBy.push({ label: d.label, value: d.value, key: d.key });
            }
        }
    }

    /**
     *
     * Set isValid status
     * @param status
     * @returns
     */
    setStatus(status: string): string | null {
        let statusValue = '';

        if (status === 'true') {
            statusValue = 'Attivo';
            return statusValue;
        } else if (status === 'false') {
            statusValue = 'Inattivo';
            return statusValue;
        } else {
            return null;
        }
    }

    /**
     * Reset additional field
     */
    resetField(event: string): void {
        delete this.paramsObject[event];
        this.filterForm.get(event)?.reset();
        this.searchBy = this.searchBy.filter(f => f.key !== event);
        this.updateTable();
    }

    /**
     * Shows search dialog on small devices
     */
    showSearchDialog(): void {
        this.displaySearchModal = true;
    }

}
