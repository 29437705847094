import { Injectable, Injector } from '@angular/core';
import { Province } from '../models/province';
import { UtsType } from '../models/utsType';
import { CustomBaseHttpLiteService, CustomSearchCriteria } from '@pandora/flowers';
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ProvincesService extends CustomBaseHttpLiteService<Province> {
    constructor(injector: Injector) {
        super(injector);
    }

    override getHost(): string {
        const base_url: string = environment.base_protocol + "://" +
        environment.geo_base_root_domain +
        (environment.geo_base_port ? ":" + environment.geo_base_port + "/" : "");

        return base_url;
    }

    buildSearchParams(criteria: CustomSearchCriteria): any {
        const params = {};
        return params;
    }

    getBaseResourceEndpoint(): string {
        return 'provinces';
    }

    getServiceName(): string {
        return 'ProvincesService';
    }

    //Insert file with provices data (waiting for be api to ajust)
    uploadProvinces(file: FormData) {
        return this.httpClient.post<File>(`${this.getHost()}provinces/upload`, file);
    }

    // get uts type by country code
    getUtsTypes(countryCode: string) {
        return this.httpClient.get<UtsType[]>(`${this.getHost()}provinces/uts_types?countryCode=${countryCode}`);
    }
}
