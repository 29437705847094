<div class="flex align-items-center">

    <div class="flex align-items-center mr-3 my-3">
        <registry-back-button [isRouter]="false"></registry-back-button>
    </div>

    <h2>
        <ng-container *ngIf="!id; else updateModeHeader">
            {{insertTitle}}
        </ng-container>

        <ng-template #updateModeHeader>
            {{editTitle}}
        </ng-template>
    </h2>

</div>
