<p-dialog header="{{dialogTitle}}" [resizable]="false" [modal]="true" [maximizable]="true" appendTo="body"
    [(visible)]="expanded" [style]="{width: customWidth? customWidth : '75vw'}" [contentStyle]="{height: '300px'}"
    (onHide)="closeDialog()">

    <registry-table id="priceHistoryTable" [dataRows]="dataRows" [pageSize]="pageSize" [columns]="columns"
        [isMobile]="isMobile" [scrollable]="true" [scrollHeight]="'flex'" [tableStyle]="'{min-width: 50rem}'"
        (tableLoaded)="onLazyLoad($event)">
    </registry-table>

</p-dialog>
