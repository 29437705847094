<div class="pt-2">
    <ng-container *ngIf="pageHeader">
        <registry-list-header [title]="title" [url]="url" [insertLabel]="insertLabel" [customHeader]="true"
            [isMobile]="isMobile"></registry-list-header>
    </ng-container>

    <registry-table-filters [filterForm]="filterForm" [keys]="keys" [options]="options" [entity]="entity"
        [quickSearch]="quickSearch" [page]="page" [categoryOptions]="categoryOptions"
        (selectedSearch)="selectOptions($event)" (filterSubmited)="searchByFilter($event)"
        (option)="onOption($event)"></registry-table-filters>

    <!--Search by chips-->
    <registry-data-table-search-by [searchBy]="searchBy" [isMobile]="isMobile" (inputCleared)="resetField($event)"
        [filterForm]="filterForm" (filtersCleared)="searchByFilter($event)"></registry-data-table-search-by>

    <registry-table id="itemsTable" [dataRows]="dataRows" [pageSize]="pageSize" [first]="first"
        [totalItems]="totalItems" [columns]="columns" [displayedColumns]="displayedColumns" [isMobile]="isMobile"
        [emptyTableMessage]="emptyTableMessage" (tableLoaded)="onLazyLoad($event)"
        (searchDialogClicked)="showSearchDialog()" (optionToggled)="toggleOption($event)"
        (pageHandle)="handlePagination($event)">
    </registry-table>
</div>

<!--Confirmation dialog when disable is called-->
<p-confirmDialog id="confirmDialog" styleClass="confirm-dialog" acceptLabel="{{'COMMON.YES' | translate}}"
    rejectLabel="{{'COMMON.NO' | translate}}" rejectButtonStyleClass="p-button-text">
</p-confirmDialog>