<flowers-base-layout [menuItems]="menuItems" [applicationTitle]="'APPLICATION.TITLE'"
    [applicationSubtitle]="'APPLICATION.SUBTITLE' | translate" [showFooter]="false" [containerFluid]="true"
    [menuOpenDefault]="true" [showMenuItemsIcon]="false">
    <router-outlet></router-outlet>

    <!--Toast component-->
    <p-toast></p-toast>
    <registry-load-spinner></registry-load-spinner>
</flowers-base-layout>

<!-- <flowers-loading-spinner></flowers-loading-spinner> -->
