<!--Date grid-->
<div class="grid" [formGroup]="formGroup">

    <!--From date field-->
    <div class="field  md:col-6">
        <label>Validità dal</label>
        <p-calendar id="fromDate" appendTo="body" styleClass="sicer-dropdown w-full" [showClear]="true"
            [minDate]="minFromDate" [maxDate]="maxFromDate" (onSelect)="onSelectDate($event)"
            (onInput)="onInputDate($event.target.value)" (onClear)="onClear($event)" formControlName="fromDate"
            [showIcon]="true" placeholder="Scegli la data di inizio" dateFormat="dd/mm/yy" dataType="string"
            registryDateMask>
        </p-calendar>
    </div>

    <!--To date field-->
    <div class="field  md:col-6">
        <label>Validità al</label>
        <p-calendar id="toDate" appendTo="body" [minDate]="minToDate" styleClass="sicer-dropdown w-full"
            formControlName="toDate" [showClear]="true" [showIcon]="true" placeholder="Scegli la data di fine"
            dateFormat="dd/mm/yy" dataType="string" registryDateMask></p-calendar>
    </div>

</div>