import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'registry-add-edit-footer',
    templateUrl: './add-edit-footer.component.html',
})
export class AddEditFooterComponent {
    readonly submit = {
        label: 'COMMON.SAVE',
        command: () => this.submitData(),
        icon: 'pi pi-save'
    };
    @Output() data = new EventEmitter();

    submitData(): void {
        this.data.emit();
    }
}
