import { Component } from '@angular/core';
import { LoadSpinnerService } from '../../services/load-spinner.service';

@Component({
    selector: 'registry-load-spinner',
    templateUrl: './load-spinner.component.html',
    styleUrls: ['./load-spinner.component.scss'],
})
export class LoadSpinnerComponent {
    isLoading$ = this.loadSpinnerService.loading$;
    constructor(public loadSpinnerService: LoadSpinnerService) { }
}
