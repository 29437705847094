import { Component, Injector } from '@angular/core';
import { FlowersBasePageComponent } from '@pandora/flowers';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'fe-root',
  templateUrl: './app.component.html'
})
export class AppComponent extends FlowersBasePageComponent {
  menuItems: MenuItem[] = [];

  constructor(
    injector: Injector,
    private readonly router: Router,
    private readonly translateService: TranslateService,) {
    super(injector);
  }

  pageName(): string {
    return 'AppComponent';
  }

  ngOnInitForChildren(): void {
    this.menuItems = [
      {
        id: 'banks',
        label: this.translateService.instant('BANKS_MODULE.SECTION_TITLE'),
        command: () => this.router.navigate(['banks']),
      },
      {
        id: 'teritorialUnits',
        label: 'Unità Territoriali',
        items: [
          {
            id: 'regions',
            label: this.translateService.instant('REGIONS_MODULE.SECTION_TITLE'),
            command: () => this.router.navigate(['/teritorial-units/regions']),
          },
          {
            id: 'provinces',
            label: this.translateService.instant('PROVINCES_MODULE.SECTION_TITLE'),
            command: () => this.router.navigate(['/teritorial-units/provinces']),
          },
          {
            id: 'municipalities',
            label: this.translateService.instant('MUNICIPALITIES_MODULE.SECTION_TITLE'),
            command: () => this.router.navigate(['/teritorial-units/municipalities']),
          }
        ],
      },
      {
        id: 'uom',
        label: this.translateService.instant('UOM_MODULE.SECTION_TITLE'),
        items: [
          {
            id: 'internalUomLink',
            label: this.translateService.instant('UOM_MODULE.INTERNAL_UOM.SECTION_TITLE'),
            command: () => this.router.navigate(['/units-of-measurement/internal-uom']),
          },
          {
            id: 'externalUomLink',
            label: this.translateService.instant('UOM_MODULE.EXTERNAL_UOM.SECTION_TITLE'),
            command: () => this.router.navigate(['/units-of-measurement/external-uom']),
          },
        ],
      },
      {
        id: 'vat',
        label: this.translateService.instant('VAT_MODULE.SECTION_TITLE'),
        items: [
          {
            id: 'vatCodeLink',
            label: this.translateService.instant('VAT_MODULE.VAT_CODES.SECTION_TITLE'),
            command: () => this.router.navigate(['/vat/vat-codes']),
          },
          {
            id: 'vatTypeLink',
            label: this.translateService.instant('VAT_MODULE.VAT_TYPES.SECTION_TITLE'),
            command: () => this.router.navigate(['/vat/vat-types']),
          },
          {
            id: 'italianVatCodeLink',
            label: this.translateService.instant('VAT_MODULE.OIT_VAT_CODES.SECTION_TITLE'),
            command: () => this.router.navigate(['/vat/italian-official-vat-codes']),
          },
        ],
      },
      {
        id: 'regimes',
        label: this.translateService.instant('REGIMES_MODULE.SECTION_TITLE'),
        command: () => this.router.navigate(['/regimes']),
      },
      {
        id: 'structures',
        label: this.translateService.instant('STRUCTURES_MODULE.SECTION_TITLE'),
        items: [
          {
            id: 'structureLink',
            label: this.translateService.instant('STRUCTURES_MODULE.SECTION_TITLE'),
            command: () => this.router.navigate(['/structures/structures']),
          }
        ]
      },
      {
        id: 'products',
        label: this.translateService.instant('PRODUCTS_MODULE.SECTION_TITLE'),
        items: [
          {
            id: 'productLink',
            label: this.translateService.instant('PRODUCTS_MODULE.SECTION_TITLE'),
            command: () => this.router.navigate(['/products/products']),
          },
          {
            id: 'productCategoryLink',
            label: this.translateService.instant('PRODUCTS_MODULE.PRODUCTS_CATEGORY.SECTION_TITLE'),
            command: () => this.router.navigate(['/products/product-category']),
          }
        ],
      },
      {
        id: 'accounts',
        label: this.translateService.instant('ACCOUNTS_MODULE.SECTION_TITLE'),
        items: [
          {
            id: 'accountTypeLink',
            label: this.translateService.instant('ACCOUNTS_MODULE.ACCOUNTS_TYPE.SECTION_TITLE'),
            command: () => this.router.navigate(['/accounts/account-types']),
          },
          {
            id: 'chartOfAccountsLink',
            label: this.translateService.instant('ACCOUNTS_MODULE.CHART_OF_ACCOUNTS.SECTION_TITLE'),
            command: () => this.router.navigate(['/accounts/chart-of-accounts']),
          }
        ],
      },
      {
        id: 'warehouseCausals',
        label: this.translateService.instant('WAREHOUSE_CAUSALS_MODULE.SECTION_TITLE'),
        command: () => this.router.navigate(['/warehouseCausals']),
      },
      {
        id: 'warehouse',
        label: this.translateService.instant('WAREHOUSE_MODULE.SECTION_TITLE'),
        command: () => this.router.navigate(['/warehouse']),
      },
    ];
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  ngOnDestroyForChildren(): void { /* TODO document why this method 'ngOnDestroyForChildren' is empty */ }
}
