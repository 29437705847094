<p-dialog header="{{header}}" [(visible)]="displayModal" [modal]="true"
    styleClass="pb-1 form-dialog" [draggable]="false" [resizable]="false" (onHide)="closeModal()">
    
    <!--Dialog header template-->
    <ng-content selet="[header]"></ng-content>

    <!--Dialog content template-->
    <ng-content select="[content]"></ng-content>

    <!--Dialog footer template-->
    <ng-template pTemplate="footer">
        <ng-content select="[footer]"></ng-content>
    </ng-template>
</p-dialog>
