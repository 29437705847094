import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { checkDateInputs } from '../../utils/check-date-inputs.component';
import { formattingDateFromString } from '../../utils/formatting-date';

@Component({
    selector: 'registry-validity-date',
    templateUrl: './validity-date.component.html',
    styleUrls: ['./validity-date.component.scss'],
})
export class ValidityDateComponent implements OnInit {
    @Input() minToDate!: Date;
    @Input() minFromDate!: Date;
    @Input() maxFromDate!: Date;
    @Input() formGroup!: FormGroup;
    @Output() newMinFromDateEvent = new EventEmitter<Date | string>();

    ngOnInit(): void {
        this.fromDateValueChanges();
    }

    onSelectDate(value: Date) {
        this.newMinFromDateEvent.emit(value);
    }

    onInputDate(value: string) {
        if (isFinite(formattingDateFromString(value).getTime())) {
            this.newMinFromDateEvent.emit(value);
        }
    }

    onClear(value: undefined) {
        this.newMinFromDateEvent.emit(value);
    }

    fromDateValueChanges(): void {
        const fromDate = this.formGroup.get('fromDate')!;
        const toDate = this.formGroup.get('toDate')!;

        fromDate?.valueChanges.subscribe((res: string) => {
            if (res) {
                this.minToDate = formattingDateFromString(res);
            } else {
                this.minToDate = new Date('Invalid Date');
            }

            if (toDate.value && this.minToDate > formattingDateFromString(toDate.value)) {
                toDate.setValue(null);
            }

            checkDateInputs(this.minToDate, toDate);
        });
    }

}
