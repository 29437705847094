<ng-container *ngIf="!customHeader; else custom">
    <div class="flex justify-content-between align-items-center">
        <h2 class="my-1">{{title | translate}}</h2>
        <flowers-button [item]="insertBtnItem" [id]="'insertButton'" [extraClass]="'text-xs'"></flowers-button>
    </div>
</ng-container>

<ng-template #custom>
    <div [ngClass]="isMobile ? 'flex-column' : 'flex justify-content-between align-items-center' ">
        <h2 class="my-1">{{title | translate}}</h2>
        <div [ngClass]="isMobile ? 'flex justify-content-between align-items-end mb-3' : '' ">
            <flowers-button *ngIf="uploadLabel" [item]="uploadBtnItem" [id]="'uploadButton'"
                [extraClass]="'text-xs mr-2'"></flowers-button>
            <flowers-button *ngIf="downloadLabel" [item]="downloadBtnItem" [id]="'downloadButton'"
                [extraClass]="'text-xs mr-2'"></flowers-button>
            <flowers-button [item]="insertBtnItem" [id]="'insertButton'" [extraClass]="'text-xs'"></flowers-button>
        </div>
    </div>
</ng-template>