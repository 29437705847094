import {
    TranslateLoader,
    TranslateModuleConfig,
    TranslateService,
  } from '@ngx-translate/core';
  import {
    HttpBackend,
    HttpRequest,
    HttpResponse,
  } from '@angular/common/http';
  import { Observable, filter, map } from 'rxjs';
  import { APP_INITIALIZER, Injector } from '@angular/core';
  import { LOCATION_INITIALIZED } from '@angular/common';
  import { it } from '@pandora/flowers';
  
  export class TranslateHttpLoader implements TranslateLoader {
    constructor(
        private httpHandler: HttpBackend,
        public prefix: string,
        public suffix: string,
    ) { }
  
    getTranslation(lang: string): Observable<unknown> {
        const httpRequest = new HttpRequest('GET', `${this.prefix}${lang}${this.suffix}`);
  
        return this.httpHandler.handle(httpRequest)
            .pipe(
                filter((httpEvent) => httpEvent instanceof HttpResponse),
                map((httpEvent) => {
                  if (httpEvent instanceof HttpResponse) {
                    return httpEvent.body;
                  }
                }),
            );
    }
  }
  
  export function translateLoaderFactory(httpHandler: HttpBackend): TranslateLoader {
    return new TranslateHttpLoader(httpHandler, './assets/i18n/', '.json');
  }
  
  export function translateResolver(
    translateService: TranslateService,
    injector: Injector
  ) {
    return () =>
      new Promise<any>((resolve: any) => {
        const locationInitialized = injector.get(
          LOCATION_INITIALIZED,
          Promise.resolve(null)
        );
        locationInitialized.then(() => {
          const language = 'it';
          translateService.setDefaultLang(language);
          translateService.use(language).subscribe((json) => {
            // merge labels from libraries with those of the application
            translateService.setTranslation(language, it, true);
            resolve(true);
          });
        });
      });
  }
  
  export const TRANSLATE_LOADER: TranslateModuleConfig = {
    loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [
            HttpBackend,
        ],
    },
  };
  
  export const TRANSLATE_PROVIDER = {
    provide: APP_INITIALIZER,
    useFactory: translateResolver,
    deps: [TranslateService, Injector],
    multi: true,
  };
  