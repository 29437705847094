import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Observable, finalize } from 'rxjs';
import { LoadSpinnerService } from '../services/load-spinner.service';

@Injectable()
export class LoadSpinnerInterceptor implements HttpInterceptor {

    constructor(private loadSpinnerService: LoadSpinnerService) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        this.loadSpinnerService.set(true);
        return next.handle(request).pipe(
            finalize(() => {
                this.loadSpinnerService.set(false);
            })
        );
    }
}
