import { Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'registry-form-dialog',
  templateUrl: './form-dialog.component.html',
  styleUrls: ['./form-dialog.component.scss']
})
export class FormDialogComponent {
  @Input() header!: string;
  @Input() displayModal!: boolean;
  @Output() modalClosed = new EventEmitter();

  // Global function for modal close
  closeModal() {
    this.displayModal = false;
    this.modalClosed.emit();
  }
}
