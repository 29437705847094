import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DataTableComponent } from '../data-table/data-table.component';
import { TableColumn } from '../../models/tabe-column';

@Component({
    selector: 'registry-table-dialog',
    templateUrl: './table-dialog.component.html'
})
export class TableDialogComponent<T> extends DataTableComponent<T> {
    @Input() expanded!: boolean;
    @Input() tableColumns!: TableColumn[];
    @Input() dialogTitle!: string;
    @Input() data!: T[];
    @Input() customWidth? : string;
    @Output() dialogClosed = new EventEmitter();

    // Initialize table
    getTable(): void {
        this.displayedColumns = this.tableColumns;
        this.setData();
    }

    /**
    * Get data for this component
    */
    setData(): void {
        this.loadTable();
    }

    setComponentsParams(): void {
        this.setDefaultParams();
    }

    updateTable(): void {
        this.items = this.data;
    }

    closeDialog(): void {
        this.dialogClosed.emit(false);
    }
}
