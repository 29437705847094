import { Injectable, Injector } from '@angular/core';
import { Region } from '../models/region';
import { CustomBaseHttpLiteService, CustomSearchCriteria } from '@pandora/flowers';
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class RegionsService extends CustomBaseHttpLiteService<Region> {
    constructor(injector: Injector) {
        super(injector);
    }

    override getHost(): string {
        const base_url: string = environment.base_protocol + "://" +
        environment.geo_base_root_domain +
        (environment.geo_base_port ? ":" + environment.geo_base_port + "/" : "");

        return base_url;
    }

    buildSearchParams(criteria: CustomSearchCriteria): any {
        const params = {};
        return params;
    }

    getBaseResourceEndpoint(): string {
        return 'regions';
    }

    getServiceName(): string {
        return 'RegionsService';
    }
}
