import keycloakConfig from "../keycloak.config";

export const environment = {
  production: false,
  base_protocol: "https",
  keycloak: keycloakConfig,
  base_root_domain: "registry-be.pandoradevelop.it",
  geo_base_root_domain: "geo-registry-be.pandoradevelop.it",
  //if there is no port, leave base_port empty string
  base_port: "443",
  geo_base_port: "443"
};
