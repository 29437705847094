import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
    selector: 'registry-back-button',
    templateUrl: './back-button.component.html',
})
export class BackButtonComponent {
    @Input() url?: string;
    @Input() isRouter!: boolean;
    backBtnItem = {
        command: () => this.isRouter ? this.router.navigate([this.url]) : this.location.back(),
        icon: 'pi pi-arrow-left'
    };

    constructor(private router: Router, private location: Location) { }
}
