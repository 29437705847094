import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'registry-list-header',
    templateUrl: './list-header.component.html',
})
export class ListHeaderComponent implements OnInit {
    @Input() customHeader = false;
    @Input() isMobile!: boolean;
    @Input() title!: string;
    @Input() url!: string;
    @Input() insertLabel!: string;
    @Input() downloadLabel?: string;
    @Input() uploadLabel?: string;
    @Output() downloadData = new EventEmitter();
    @Output() uploadData = new EventEmitter();

    insertBtnItem = {
        label: this.insertLabel,
        command: () => this.router.navigate([this.url], { relativeTo: this.route }),
        icon: 'pi pi-plus-circle'
    };

    downloadBtnItem = {
        label: this.downloadLabel,
        command: () => this.download(),
        icon: 'pi pi-download'
    };

    uploadBtnItem = {
        label: this.uploadLabel,
        command: () => this.upload(),
        icon: 'pi pi-upload'
    };

    constructor(private router: Router, private route: ActivatedRoute) { }

    ngOnInit(): void {
        this.insertBtnItem.label = this.insertLabel;

        if (this.customHeader) {
            this.downloadBtnItem.label = this.downloadLabel;
            this.uploadBtnItem.label = this.uploadLabel;
        }
    }

    download(): void {
        this.downloadData.emit();
    }

    upload(): void {
        this.uploadData.emit();
    }
}
