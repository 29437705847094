<form id="addressForm" [formGroup]="formData" *ngIf="formData">
    <!--Address grid-->
    <div formGroupName="address" class="px-2 mb-3 border-1 border-round border-300">
        <h4>Indirizzo</h4>

        <div class="grid">

            <!--Street name field-->
            <div class="field  md:col-4">
                <custom-input-text id="street" formControlName="street" customFormatInputText
                    placeholder="Inserire nome della via" label="Via" [error-labels]="errorLabels['maxLengthStreet']"
                    class="p-inputtext-sm"></custom-input-text>
            </div>

            <!--Street number field-->
            <div class="field  md:col-4">
                <custom-input-text id="streetNumber" formControlName="streetNumber" customFormatInputText
                    placeholder="Inserire numero civico" label="Numero civico"
                    [error-labels]="errorLabels['maxLengthStreetNumber']" class="p-inputtext-sm"></custom-input-text>
            </div>

            <!--Postal code field-->
            <div class="field  md:col-4">
                <custom-input-text id="postalCode" formControlName="postalCode" customFormatInputText
                    placeholder="Inserire cap" label="Cap" [error-labels]="errorLabels['maxLengthPostalCode']"
                    class="p-inputtext-sm"></custom-input-text>
            </div>

        </div>

        <div class="grid">

            <!--Country field-->
            <div class="field  md:col-6">
                <label for="country">Paese<span> *</span></label>
                <p-dropdown formControlName="country" id="structureCountry" name="country" [options]="countries"
                    [filter]="true" optionValue="value" optionLabel="name" [showClear]="true" appendTo="body"
                    placeholder="Seleziona paese" styleClass="sicer-dropdown w-full">
                </p-dropdown>
                <small *ngIf="hasErrors('address.country', 'required')" class="p-error block">Paese campo è
                    vuoto.</small>
            </div>

            <!--Region field-->
            <div class="field  md:col-6">
                <label for="region">Regione<span> *</span></label>
                <p-dropdown formControlName="regionId" name="region" id="structureRegion" [options]="regions"
                    [filter]="true" optionValue="id" optionLabel="description" [showClear]="true" appendTo="body"
                    placeholder="Seleziona regione" styleClass="sicer-dropdown w-full">
                </p-dropdown>
                <small *ngIf="hasErrors('address.region', 'required')" class="p-error block">Campo regione è
                    vuoto.</small>
            </div>
        </div>

        <div class="grid">

            <!--Province field-->
            <div class="field  md:col-6">
                <label for="provinceId">Provincia<span> *</span></label>
                <p-dropdown formControlName="provinceId" name="provinceId" id="structureProvince" [options]="provinces"
                    [filter]="true" optionValue="id" optionLabel="description" [showClear]="true" appendTo="body"
                    placeholder="Seleziona provincia" styleClass="sicer-dropdown w-full">
                </p-dropdown>
                <small *ngIf="hasErrors('address.province', 'required')" class="p-error block">Il campo della
                    provincia è vuoto.</small>
            </div>

            <!--City field-->
            <div class="field  md:col-6">
                <label for="city">Citta<span> *</span></label>
                <p-dropdown formControlName="cityId" id="city" name="city" [options]="municipalities" [filter]="true"
                    optionValue="id" optionLabel="description" [showClear]="true" appendTo="body"
                    placeholder="Seleziona citta" styleClass="sicer-dropdown w-full">
                </p-dropdown>
                <small *ngIf="hasErrors('address.city', 'required')" class="p-error block">Il campo della
                    citta è vuoto.</small>
            </div>
        </div>

        <registry-validity-date [minToDate]="minToDateAddress" [minFromDate]="minToDate" [formGroup]="addressData"
            (newMinFromDateEvent)="setMinToDateAddress($event)"></registry-validity-date>

    </div>
</form>