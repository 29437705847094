import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Country } from '../../models/country';

@Injectable({
    providedIn: 'root'
})
export class CountryService {
    // Countries mock array
    countries: Country[] = [
        { name: 'Norway', value: "NO" },
        { name: 'Serbia', value: "RS" },
        { name: 'Bosnia', value: "BS" },
        { name: 'Germany', value: "DE" },
        { name: 'Sweden', value: "SW" },
        { name: 'Belgium', value: "BL" },
        { name: 'Hungary', value: "HU" },
        { name: 'Greece', value: "GR" },
        { name: 'England', value: "EN" },
        { name: 'Chech', value: "CZ" },
        { name: 'Croatia', value: "CR" },
        { name: 'Austria', value: "AU" },
        { name: 'Italy', value: "IT" },
        { name: 'Estonia', value: "ES" },
        { name: 'Bulgaria', value: "BG" }
    ];

    // Get countries from the list
    getCountries(): Observable<Country[]> {
        return of<Country[]>(this.countries);
    }

    // Get country by country code
    getCountryByCode(code: string) {
        return this.countries.find(country => country.value === code);
    }

    // Get country by country code
    getCountryByName(name: string) {
        return this.countries.find(country => country.name === name);
    }
}
