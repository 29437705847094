<p-table [value]="dataRows" responsiveLayout="scroll" [lazy]="true" [loading]="isLoading" [scrollable]="scrollable"
    scrollHeight="{{scrollHeight}}" [tableStyle]="{tableStyle}" (onLazyLoad)="onLazyLoad($event)" [rows]="pageSize"
    [responsive]="true" dataKey="id" styleClass="p-datatable-lg p-datatable-responsive">

    <!--Table header-->
    <ng-template pTemplate="header">

        <!--Table default header-->
        <tr>
            <th *ngFor="let col of columns; let i = index" id="{{col.rowLabel}}" pSortableColumn="{{col.value}}"
                [class]="col.headerClass" [style]="i === columns.length - 1 ? 'width: 150px' : ''">

                <ng-container *ngIf="!col.isSearch; else tableHeader">
                    {{col.rowLabel}} <p-sortIcon *ngIf="col.value" field="{{col.value}}"></p-sortIcon>
                </ng-container>

                <ng-template #tableHeader>
                    <button pButton pRipple (click)="showSearchDialog()" type="button" icon="pi pi-search"
                        class="p-button-rounded p-button-text text-center"></button>
                </ng-template>
            </th>
        </tr>
    </ng-template>

    <!--Table body template-->
    <ng-template pTemplate="body" let-item let-ri="rowIndex" let-expanded="expanded">
        <tr>
            <ng-container *ngIf="!isLoading; else progressTable">
                <td *ngFor="let col of columns" [ngClass]="col.columnClass"
                    [ngStyle]="{'minWidth': col.minWidth ? col?.minWidth : 'auto'}">
                    <ng-container *ngIf="!col.isOptions && !col.isSearch">

                        <!-- Set badge in status column -->
                        <ng-container *ngIf="col.colLabel === 'isValid'; else rest">
                            <p-tag [value]="item[col.colLabel]"
                                severity="{{item[col.colLabel] === 'Attivo' ? 'success' : 'danger'}}">
                            </p-tag>
                        </ng-container>

                        <ng-template #rest>
                            <ng-container *ngIf="!col.options">
                                <ng-container *ngIf="col?.types[ri]?.type === 'index'">
                                    {{ri + 1}}
                                </ng-container>

                                <ng-container *ngIf="col?.types[ri]?.type === 'text'">
                                    {{item[col.colLabel] !== null ? item[col.colLabel] : '/'}}
                                </ng-container>

                                <ng-container
                                    *ngIf="col?.types[ri]?.type === 'input' && col?.types[ri]?.inputData && col?.types[ri]?.inputData?.formArray">
                                    <ng-container [formGroup]="form">
                                        <ng-container formArrayName="{{col?.types[ri]?.inputData?.formArray}}">
                                            <ng-container
                                                *ngFor="let ctrl of getFormArray(col?.types[ri]?.inputData?.formArray)?.controls; let i = index"
                                                [formGroupName]="ri">
                                                <ng-container *ngIf="ri === i">
                                                    <ng-container
                                                        *ngIf="col?.types[ri].inputData?.input?.inputType === 'textInput'">
                                                        <input [formControlName]="col.colLabel"
                                                            name="{{item[col.colLabel]}}" type="text"
                                                            id="{{col.colLabel+i}}"
                                                            class="p-2 outline-none search-field"
                                                            [ngStyle]="{'width': col?.types[ri]?.inputData?.width ? col?.types[ri]?.inputData?.width : 'auto'}"
                                                            pInputText />
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="col?.types[ri].inputData?.input?.inputType === 'numberInput'">
                                                        <p-inputNumber #inputNum [formControlName]="col.colLabel"
                                                            inputId="{{col.colLabel+i}}" mode="decimal"
                                                            styleClass="{{col?.types[ri].inputData?.input?.required && hasArrErrors(col.colLabel, ctrl, 'required') ? 'required' : ''}}"
                                                            [minFractionDigits]="isNumber(col?.types[ri].inputData?.additionalData?.minFractionDigits) ? toNumber(col?.types[ri].inputData?.additionalData?.minFractionDigits) : 0"
                                                            [maxFractionDigits]="isNumber(col?.types[ri].inputData?.additionalData?.maxFractionDigits) ? toNumber(col?.types[ri].inputData?.additionalData?.maxFractionDigits) : 0"
                                                            [showButtons]="toBoolean(col?.types[ri].inputData?.additionalData?.showButtons)"
                                                            [min]="0"
                                                            placeholder="{{col?.types[ri].inputData?.additionalData?.showSufix ? item[col.colLabel]+'/'+item.currentQuantity : item[col.colLabel]}}"
                                                            suffix="{{col?.types[ri].inputData?.additionalData?.showSufix ? '/'+ item.currentQuantity : null}}"
                                                            [max]="col?.types[ri].inputData?.additionalData?.hasMaxValue ? item.currentQuantity : null"
                                                            [style.width]="(inputNum.value && inputNum.value.toString().length > 1 && (inputNum.value.toString().length + inputNum.suffix.toString().length - 1) * 10 + 45 > 100)? ((inputNum.value.toString().length + inputNum.suffix.toString().length) * 10 + 45 + 'px') : col?.types[ri]?.inputData?.width ? col?.types[ri]?.inputData?.width : 'auto'"
                                                            [disabled]="toBoolean(col?.types[ri]?.inputData?.disabled)"></p-inputNumber>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="col?.types[ri].inputData?.input?.inputType === 'dateInput'">
                                                        <div class="flex pr-1 w-11rem">
                                                            <p-calendar [formControlName]="col.colLabel"
                                                                id="{{col.colLabel+i}}" appendTo="body"
                                                                styleClass="sicer-dropdown date-input {{hasArrErrors(col.colLabel, ctrl, 'required') ? 'required' : ''}}"
                                                                [showIcon]="true" dateFormat="dd/mm/yy"
                                                                [required]="!col?.types[ri].inputData?.input?.required ? item.lot ? true : false : true">
                                                            </p-calendar>
                                                        </div>
                                                    </ng-container>

                                                    <ng-container
                                                        *ngIf="col?.types[ri].inputData?.input?.inputType === 'dropdown'">
                                                        <div class="flex pr-1 w-11rem">
                                                            <p-dropdown [formControlName]="col.colLabel"
                                                                [editable]="col?.types[ri].inputData?.input?.editable"
                                                                [options]="item.dropdownOptions"
                                                                optionLabel="optionLabel" optionValue="optionValue"
                                                                id="{{col.colLabel+i}}" [showClear]="true"
                                                                appendTo="body" placeholder="lotto" [filter]="true"
                                                                styleClass="sicer-dropdown w-full {{col?.types[ri].inputData?.input?.required && hasArrErrors(col.colLabel, ctrl, 'required') ? 'required' : ''}}">
                                                            </p-dropdown>

                                                        </div>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="col?.types[ri]?.type === 'numInput'">
                                    <ng-container [formGroup]="form">
                                        <p-inputNumber [formControlName]="col.colLabel" inputId="{{col.colLabel}}"
                                            mode="decimal"
                                            styleClass="{{col?.types[ri].inputData?.input?.required ? 'required' : ''}}"
                                            [minFractionDigits]="isNumber(col?.types[ri].inputData?.additionalData?.minFractionDigits) ? toNumber(col?.types[ri].inputData?.additionalData?.minFractionDigits) : 0"
                                            [maxFractionDigits]="isNumber(col?.types[ri].inputData?.additionalData?.maxFractionDigits) ? toNumber(col?.types[ri].inputData?.additionalData?.maxFractionDigits) : 0"
                                            [showButtons]="true" [min]="0" placeholder="0" value="9">

                                        </p-inputNumber>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="col?.types[ri]?.type === 'button'">
                                    <button *ngFor="let option of item[col.colLabel]" pButton
                                        (click)="toggleOption(option.id, item, ri)" id="{{option.id}}"
                                        icon="{{option.icon}}" class="{{option.class}}" style="margin-right: 5px;"
                                        pTooltip="{{option.tooltip}}" [disabled]="option.disabled"
                                        tooltipPosition="{{option.tooltipPosition}}"></button>
                                </ng-container>
                            </ng-container>
                        </ng-template>
                    </ng-container>
                </td>
            </ng-container>

            <!--Loading skeleton layout-->
            <ng-template #progressTable>
                <td *ngFor="let column of columns; let i = index">
                    <p-skeleton></p-skeleton>
                </td>
            </ng-template>
        </tr>
    </ng-template>

    <!--Expanded row for small screens-->
    <ng-template pTemplate="rowexpansion" let-item>
        <tr>
            <td class="px-4 py-1" colspan="5">
                <div *ngFor="let col of displayedColumns">
                    <ng-container *ngIf="!col.isOptions && !col.isSearch; else options">
                        <h5 class="mb-1 capitalize">{{col.rowLabel}}</h5>

                        <!-- Set badge in status column -->
                        <ng-container *ngIf="col.rowLabel === 'status'; else rest">
                            <p-tag [value]="item[col.colLabel]"
                                severity="{{item[col.colLabel] === 'Active' ? 'success' : 'danger'}}"
                                styleClass="mt-1 mb-2">
                            </p-tag>
                        </ng-container>

                        <ng-template #rest>
                            <p class="mt-1">{{item[col.colLabel] ? item[col.colLabel] : '/'}}</p>
                        </ng-template>

                    </ng-container>

                    <ng-template #options>
                        <ng-container *ngIf="col.isOptions">
                            <div class="flex">
                                <a pButton icon="pi pi-file-edit" class="p-button-sm show-details-button"
                                    style="margin-right: 5px;" (click)="editEntity(item.id)" pTooltip="Edit"
                                    tooltipPosition="top"></a>
                                <a pButton [routerLink]="['details/'+item.id]" style="margin-right: 5px;"
                                    icon="pi pi-external-link" class="p-button-sm primary-btn show-details-button"
                                    pTooltip="Show details" tooltipPosition="top"></a>
                                <ng-container *ngIf="item.isValid === 'Active'; else enableBtn">
                                    <a pButton icon="pi pi-times"
                                        class="p-button-sm p-button-danger show-details-button"
                                        (click)="disableEntity(item.id)" pTooltip="Disable" tooltipPosition="top"></a>
                                </ng-container>

                                <ng-template #enableBtn>
                                    <a pButton icon="pi pi-plus"
                                        class="p-button-sm p-button-success show-details-button"
                                        (click)="enableEntity(item.id)" pTooltip="Enable" tooltipPosition="top"></a>
                                </ng-template>
                            </div>
                        </ng-container>
                    </ng-template>
                </div>
            </td>
        </tr>
    </ng-template>

    <ng-container *ngIf="searchForm">
        <ng-template pTemplate="footer">
            <ng-container [formGroup]="searchForm">
                <tr>
                    <td *ngFor="let col of columns" [ngClass]="col.columnClass">
                        <ng-container *ngIf="searchForm?.get(col.colLabel); else rest">
                            <p-autoComplete [formControlName]="col.colLabel" [suggestions]="filteredData"
                                field="{{col.colLabel}}" [appendTo]="'body'"
                                (onSelect)="selectItem($event)"></p-autoComplete>
                        </ng-container>

                        <ng-template #rest>
                            <ng-container *ngIf="col?.types[0]?.type === 'index' && dataRows?.length; else emptyColumn">
                                {{dataRows.length + 1}}
                            </ng-container>

                            <ng-template #emptyColumn>
                                /
                            </ng-template>
                        </ng-template>
                    </td>
                </tr>
            </ng-container>
        </ng-template>
    </ng-container>
</p-table>

<!--empty table message-->
<div *ngIf="!dataRows?.length" class="bg-white flex align-items-center justify-content-center text-500 font-italic">
    <h3>{{emptyTableMessage | translate}}</h3>
</div>

<ng-container *ngIf="pageSize">
    <!--Table paginator-->
    <p-paginator id="paginator" styleClass="p-paginator-rpp-options" [first]="first" [rows]="pageSize"
        [totalRecords]="totalItems" (onPageChange)="handlePagination($event)"
        [rowsPerPageOptions]="['3','5','10','20','30']"></p-paginator>

</ng-container>