import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SearchBy } from '../../models/search-by';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'registry-data-table-search-by',
    templateUrl: './data-table-search-by.component.html',
    styleUrls: ['./data-table-search-by.component.scss'],
})
export class DataTableSearchByComponent {
    @Input() searchBy!: SearchBy[];
    @Input() isMobile!: boolean;
    @Input() filterForm!: FormGroup;
    @Output() inputCleared = new EventEmitter<string>();
    @Output() filtersCleared = new EventEmitter();

    /**
     * 
     * @param input 
     * Clear input
     */
    clearInput(input: string): void {
        this.inputCleared.emit(input);
    }

    /**
     * clearForm
     */
    clearFilters(): void {
        this.filterForm.reset();
        this.filtersCleared.emit(this.filterForm.value);
    }
}
