import { AbstractControl } from "@angular/forms";

/**
 *
 * @param value
 * @param toDate
 * Check for the fromDate value
 */
export function checkDateInputs(value: Date, toDate: AbstractControl): void {
    if (value && value.toString() !== 'Invalid Date') {
        toDate?.enable();
    }
    else {
        toDate?.disable();
        toDate?.setValue(null);
    }
}
