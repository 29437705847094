import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TableParams } from '../../models/table-params';

@Component({
    selector: 'registry-data-table-search',
    templateUrl: './data-table-search.component.html',
    styleUrls: ['./data-table-search.component.scss'],
})
export class DataTableSearchComponent {
    @Input() filterForm!: FormGroup;
    @Input() isMobile!: boolean;
    @Input() isAdditionalFilter!: boolean;
    @Input() hasAdditionalSearch!: boolean;
    @Output() filterSubmited = new EventEmitter<TableParams>();
    @Output() filtersCleared = new EventEmitter();

    /**
      * Search by filter
      */
    searchByFilter(): void {
        this.filterSubmited.emit(this.filterForm.value);
    }

    /**
     * Clear filter form
     */
    clearFilters(): void {
        this.filtersCleared.emit();
    }
}
