import { Component, Output, EventEmitter } from '@angular/core';
import { TableParams } from '../../../models/table-params';

@Component({
    selector: 'registry-action-buttons',
    templateUrl: './action-buttons.component.html',
})
export class ActionButtonsComponent {
    @Output() filterSubmited = new EventEmitter<TableParams>();
    @Output() filtersCleared = new EventEmitter();

    /**
      * Search by filter
      */
    searchByFilter(): void {
        this.filterSubmited.emit();
    }

    /**
     * Clear filter form
     */
    clearFilters(): void {
        this.filtersCleared.emit();
    }
}
