import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ToastMessage } from '../../models/toast-message';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private messageService: MessageService) { }

  /**
   * 
   * @param message 
   */
  addToast(message: ToastMessage): void {
    this.messageService.add({
      severity: message.severity,
      summary: message.summary,
      detail: message.detail,
    });
  }
}
