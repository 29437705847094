import { Component, Input } from '@angular/core';

@Component({
    selector: 'registry-add-edit-header',
    templateUrl: './add-edit-header.component.html',
})
export class AddEditHeaderComponent {
    @Input() insertTitle!: string;
    @Input() editTitle!: string;
    @Input() id?: number;
}
