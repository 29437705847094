import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormDialogComponent } from './components/form-dialog/form-dialog.component';
import { WebkitModule } from '@fe/webkit';
import { ProvincesService } from '../teritorial-units/provinces/services/provinces.service';
import { RegionsService } from '../teritorial-units/regions/services/regions.service';
import { TableComponent } from './components/table/table.component';
import { FormComponent } from './components/form/form.component';
import { DataTableSearchComponent } from './components/data-table-search/data-table-search.component';
import { DataTableSearchByComponent } from './components/data-table-search-by/data-table-search-by.component';
import { ActionButtonsComponent } from './components/data-table-search/action-buttons/action-buttons.component';
import { HttpLanguageInterceptor } from './interceptors/http-language.interceptor';
import { TableFiltersComponent } from './components/table-filters/table-filters.component';
import { TableDialogComponent } from './components/table-dialog/table-dialog.component';
import { ValidityDateComponent } from './components/validity-date/validity-date.component';
import { DateMaskDirective } from './directives/date-mask.directive';
import { FlowersModule } from '@pandora/flowers';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { LoadSpinnerComponent } from './components/load-spinner/load-spinner.component';
import { LoadSpinnerInterceptor } from './interceptors/load-spinner.interceptor';
import { AddEditFooterComponent } from './components/add-edit/add-edit-footer/add-edit-footer.component';
import { AddEditHeaderComponent } from './components/add-edit/add-edit-header/add-edit-header.component';
import { EditDisableEnableButtonsComponent } from './components/details/edit-disable-enable-buttons/edit-disable-enable-buttons.component';
import { ListHeaderComponent } from './components/list/list-header/list-header.component';
import { AddressComponent } from './components/address/address.component';
import { EnableDisableService } from './services/enable-disable.service';
import { TablePageComponent } from './components/table-page/table-page.component';

/**
 * Here we have common components and other modules that we need to import inside every other module of our application.
 * You do not need to import them again and again (like the CommonModule). We only need to add the Shared module to each module to access to common components.
 */

// Shared Module
@NgModule({
    // Used by this module
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        HttpClientModule,
        WebkitModule,
        FlowersModule,
    ],
    // Module components
    declarations: [
        FormDialogComponent,
        TableComponent,
        FormComponent,
        DataTableSearchComponent,
        DataTableSearchByComponent,
        ActionButtonsComponent,
        TableFiltersComponent,
        TableDialogComponent,
        ValidityDateComponent,
        DateMaskDirective,
        BackButtonComponent,
        LoadSpinnerComponent,
        AddEditFooterComponent,
        AddEditHeaderComponent,
        EditDisableEnableButtonsComponent,
        ListHeaderComponent,
        AddressComponent,
        TablePageComponent,
    ],
    // Shared components
    exports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        HttpClientModule,
        FormDialogComponent,
        TableComponent,
        FormComponent,
        WebkitModule,
        DataTableSearchComponent,
        DataTableSearchByComponent,
        TableFiltersComponent,
        TableDialogComponent,
        ValidityDateComponent,
        FlowersModule,
        BackButtonComponent,
        LoadSpinnerComponent,
        AddEditFooterComponent,
        AddEditHeaderComponent,
        EditDisableEnableButtonsComponent,
        ListHeaderComponent,
        AddressComponent,
        TablePageComponent
    ],
    // Used by this module
    providers: [
        ProvincesService,
        RegionsService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpLanguageInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoadSpinnerInterceptor,
            multi: true,
        },
        EnableDisableService,
    ],
})
export class SharedModule {}
