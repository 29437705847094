import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';

// App lazy routes
const routes: Routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },

    {
        path: 'home',
        component: HomeComponent,
        data: { title: 'Home', breadcrumb: 'Home' },
      },
      {
        path: ':idp/home',
        component: HomeComponent,
        data: { title: 'Home', breadcrumb: 'Home' },
      },
    {
        path: 'banks',
        loadChildren: () =>
            import('./banks/banks.module').then((m) => m.BanksModule),
    },
    {
        path: 'teritorial-units',
        loadChildren: () =>
            import('./teritorial-units/teritorial-units.module').then(
                (m) => m.TeritorialUnitsModule
            ),
    },
    {
        path: 'units-of-measurement',
        loadChildren: () =>
            import('./units-of-measurement/units-of-measurement.module').then(
                (m) => m.UnitsOfMeasurmentModule
            ),
    },
    {
        path: 'regimes',
        loadChildren: () =>
            import('./regimes/regimes.module').then((m) => m.RegimesModule),
    },
    {
        path: 'vat',
        loadChildren: () => import('./vat/vat.module').then((m) => m.VatModule),
    },
    {
        path: 'accounts',
        loadChildren: () =>
            import('./accounts/accounts.module').then((m) => m.AccountsModule),
    },
    {
        path: 'structures',
        loadChildren: () =>
            import('./structure/structure.module').then(
                (m) => m.StructureModule
            ),
    },
    {
        path: 'products',
        loadChildren: () =>
            import('./products/products.module').then((m) => m.ProductsModule),
    },
    {
        path: 'warehouseCausals',
        loadChildren: () =>
            import('./warehouse-causal/warehouse-causal.module').then((m) => m.WarehouseCausalModule),
    },
    {
        path: 'warehouse',
        loadChildren: () =>
            import('./warehouse/warehouse.module').then((m) => m.WarehouseModule),
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: true
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule { }
