<div class="filter-form md:px-3 py-2 mt-2">
    <div class="grid-nogutter">
        <div class="flex flex-column md:flex-row md:align-items-center gap-3">
            <ng-content select="[filters]"></ng-content>

            <ng-container *ngIf="!isMobile && !isAdditionalFilter">
                <registry-action-buttons (filtersCleared)="clearFilters()"
                    (filterSubmited)="searchByFilter()"></registry-action-buttons>
            </ng-container>
        </div>

        <ng-container *ngIf="hasAdditionalSearch">
            <p-accordion id="additionalSearch" styleClass="accordion" (onOpen)="isAdditionalFilter = true"
                (onClose)="isAdditionalFilter = false">
                <p-accordionTab header="Ricerca Avanzata">
                    <ng-content select="[additionalFilters]"></ng-content>
                </p-accordionTab>
            </p-accordion>
        </ng-container>
    </div>

    <ng-container *ngIf="!isMobile && isAdditionalFilter">
        <registry-action-buttons (filtersCleared)="clearFilters()"
            (filterSubmited)="searchByFilter()"></registry-action-buttons>
    </ng-container>
</div>