<div class="flex justify-content-end align-items-center gap-1 my-2">
    <div *ngIf="quickSearch" class="mr-auto">
        <p-selectButton class="p-button-sm" optionLabel="label" optionValue="value" value="Tutti"
            [options]="categoryOptions" (onOptionClick)="onOption($event)" [(ngModel)]="page"></p-selectButton>
    </div>

    <span>Cerca per: </span>
    <form [formGroup]="filterForm" id="tableFiltersForm">
        <div class="field p-inputgroup mb-0">
            <ng-container *ngFor="let field of keys">
                <ng-container *ngIf="!field.parent; else formGroupTemplate">
                    <ng-container *ngIf="searchBy?.key === field.key && searchBy?.id === field.id">

                        <ng-container *ngIf="field.inputType ==='text'">
                            <input [formControlName]="field.key" name="{{field.key}}{{entity}}" type="text"
                                id="{{field.key}}" placeholder="{{field.placeholder}}"
                                class="p-2 outline-none search-field" pInputText />
                        </ng-container>

                        <ng-container *ngIf="field.inputType ==='dropdown'">
                            <p-dropdown id="{{field.key}}" [options]="options" placeholder="{{field.placeholder}}"
                                optionValue="value" optionLabel="name" [formControlName]="field.key" [filter]="true"
                                (onChange)="searchByFilter()" appendTo="body" styleClass="sicer-dropdown search-field">
                            </p-dropdown>
                        </ng-container>

                        <ng-container *ngIf="field.inputType === 'triStateCheckbox'">
                            <div class="field-checkbox mb-0 mx-2">
                                <p-triStateCheckbox [formControlName]="field.key" id="{{field.key}}"
                                    (onChange)="searchByFilter()"></p-triStateCheckbox>
                                <label for="{{field.key}}">{{field.name}}</label>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="field.inputType === 'inputNumber'">
                            <div class="flex pr-1 w-10rem">
                                <p-inputNumber [formControlName]="field.key" id="{{field.key}}"
                                    (onInput)="searchByFilter()" mode="decimal" [showButtons]="true" [min]="1"
                                    placeholder="{{field.placeholder}}"></p-inputNumber>
                            </div>
                        </ng-container>

                    </ng-container>
                </ng-container>

                <ng-template #formGroupTemplate>
                    <ng-container *ngIf="!field.parentParent; else formGroupParentParentTemplate">
                        <ng-container formGroupName="{{field.parent}}">
                            <ng-container *ngIf="searchBy?.key === field.key && searchBy?.id === field.id">

                                <ng-container *ngIf="field.inputType ==='text'">
                                    <input [formControlName]="field.key" name="{{field.key}}{{entity}}" type="text"
                                        id="{{field.key}}" placeholder="{{field.placeholder}}"
                                        class="p-2 outline-none search-field" pInputText />
                                </ng-container>

                                <ng-container *ngIf="field.inputType ==='dropdown'">
                                    <p-dropdown id="{{field.key}}" [options]="options"
                                        placeholder="{{field.placeholder}}" optionValue="value" optionLabel="name"
                                        [formControlName]="field.key" [filter]="true" (onChange)="searchByFilter()"
                                        appendTo="body" styleClass="sicer-dropdown search-field">
                                    </p-dropdown>
                                </ng-container>

                                <ng-container *ngIf="field.inputType === 'triStateCheckbox'">
                                    <div class="field-checkbox mb-0 mx-2">
                                        <p-triStateCheckbox [formControlName]="field.key" id="{{field.key}}"
                                            (onChange)="searchByFilter()"></p-triStateCheckbox>
                                        <label for="{{field.key}}">{{field.name}}</label>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="field.inputType === 'inputNumber'">
                                    <div class="flex pr-1 w-10rem">
                                        <p-inputNumber [formControlName]="field.key" id="{{field.key}}"
                                            (onInput)="searchByFilter()" mode="decimal" [showButtons]="true" [min]="1"
                                            placeholder="{{field.placeholder}}"></p-inputNumber>
                                    </div>
                                </ng-container>

                            </ng-container>
                        </ng-container>
                    </ng-container>

                    <ng-template #formGroupParentParentTemplate>
                        <ng-container *ngIf="!field.root; else formGroupRootTemplate">
                            <ng-container formGroupName="{{field.parentParent}}">
                                <ng-container formGroupName="{{field.parent}}">
                                    <ng-container *ngIf="searchBy?.key === field.key && searchBy?.id === field.id">

                                        <ng-container *ngIf="field.inputType ==='text'">
                                            <input [formControlName]="field.key" name="{{field.key}}{{entity}}"
                                                type="text" id="{{field.key}}" placeholder="{{field.placeholder}}"
                                                class="p-2 outline-none search-field" pInputText />
                                        </ng-container>

                                        <ng-container *ngIf="field.inputType ==='dropdown'">
                                            <p-dropdown id="{{field.key}}" [options]="options"
                                                placeholder="{{field.placeholder}}" optionValue="value"
                                                optionLabel="name" [formControlName]="field.key" [filter]="true"
                                                (onChange)="searchByFilter()" appendTo="body"
                                                styleClass="sicer-dropdown search-field">
                                            </p-dropdown>
                                        </ng-container>

                                        <ng-container *ngIf="field.inputType === 'triStateCheckbox'">
                                            <div class="field-checkbox mb-0 mx-2">
                                                <p-triStateCheckbox [formControlName]="field.key" id="{{field.key}}"
                                                    (onChange)="searchByFilter()"></p-triStateCheckbox>
                                                <label for="{{field.key}}">{{field.name}}</label>
                                            </div>
                                        </ng-container>

                                        <ng-container *ngIf="field.inputType === 'inputNumber'">
                                            <div class="flex pr-1 w-10rem">
                                                <p-inputNumber [formControlName]="field.key" id="{{field.key}}"
                                                    (onInput)="searchByFilter()" mode="decimal" [showButtons]="true"
                                                    [min]="1" placeholder="{{field.placeholder}}"></p-inputNumber>
                                            </div>
                                        </ng-container>

                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-template>

                    <ng-template #formGroupRootTemplate>
                        <ng-container formGroupName="{{field.root}}">
                            <ng-container formGroupName="{{field.parentParent}}">
                                <ng-container formGroupName="{{field.parent}}">
                                    <ng-container *ngIf="searchBy?.key === field.key && searchBy?.id === field.id">

                                        <ng-container *ngIf="field.inputType ==='text'">
                                            <input [formControlName]="field.key" name="{{field.key}}{{entity}}"
                                                type="text" id="{{field.key}}" placeholder="{{field.placeholder}}"
                                                class="p-2 outline-none search-field" pInputText />
                                        </ng-container>

                                        <ng-container *ngIf="field.inputType ==='dropdown'">
                                            <p-dropdown id="{{field.key}}" [options]="options"
                                                placeholder="{{field.placeholder}}" optionValue="value"
                                                optionLabel="name" [formControlName]="field.key" [filter]="true"
                                                (onChange)="searchByFilter()" appendTo="body"
                                                styleClass="sicer-dropdown search-field">
                                            </p-dropdown>
                                        </ng-container>

                                        <ng-container *ngIf="field.inputType === 'triStateCheckbox'">
                                            <div class="field-checkbox mb-0 mx-2">
                                                <p-triStateCheckbox [formControlName]="field.key" id="{{field.key}}"
                                                    (onChange)="searchByFilter()"></p-triStateCheckbox>
                                                <label for="{{field.key}}">{{field.name}}</label>
                                            </div>
                                        </ng-container>

                                        <ng-container *ngIf="field.inputType === 'inputNumber'">
                                            <div class="flex pr-1 w-10rem">
                                                <p-inputNumber [formControlName]="field.key" id="{{field.key}}"
                                                    (onInput)="searchByFilter()" mode="decimal" [showButtons]="true"
                                                    [min]="1" placeholder="{{field.placeholder}}"></p-inputNumber>
                                            </div>
                                        </ng-container>

                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-template>
                </ng-template>
            </ng-container>

            <div class="dropdown flex gap-3">
                <p-dropdown id="searchByCtrl" [formControl]="searchByCtrl" name="searchByCtrl" [options]="keys"
                    optionLabel="name" appendTo="body" placeholder="Seleziona paese"
                    styleClass="sicer-dropdown search-by-dropdown">
                </p-dropdown>

                <button (click)="searchByFilter()" type="submit" id="tableFilterSubmit" pButton pRipple
                    icon="pi pi-search" class="primary-btn search-btn"></button>
            </div>
        </div>
    </form>
</div>